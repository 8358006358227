import { Course } from '@app/shared/course.model';
import { createReducer, on } from '@ngrx/store';
import { GoalsState, StudentAnalytics, ViewLevel } from '@wam/shared';
import { cloneDeep, isNil, omit } from 'lodash-es';

import * as goalsActions from './goals.actions';

const initialState: GoalsState = {
  level: ViewLevel.classes,
  hits: null,
  totalHits: 0,
  courses: {},
  goals: {},
  facets: {},
  selectedFacets: {},
  page: 1,
  targets: {},
  locations: {},
  benchmarks: {},
  locks: {},
  classHasStudents: {},
};

const rosteringReducer = createReducer(
  initialState,
  on(goalsActions.load, (state) => ({
    ...state,
    page: 1,
  })),
  on(goalsActions.loadSuccess, (state, { hits, totalHits }) => ({
    ...state,
    hits,
    totalHits,
  })),
  on(goalsActions.continueLoadSuccess, (state, { hits, totalHits }) => ({
    ...state,
    totalHits,
    // @ts-ignore
    hits: [...state.hits, ...hits],
  })),
  on(goalsActions.loadClassAssignmentsSuccess, (state, { courses }) => ({
    ...state,
    courses: { ...state.courses, ...courses },
  })),
  on(goalsActions.updateClassHasStudents, (state, { classUuid, hasStudents }) => ({
    ...state,
    classHasStudents: {
      ...state.classHasStudents,
      [classUuid]: hasStudents,
    },
  })),
  on(goalsActions.loadStudentCoursesSuccess, (state, { student, courses }) => ({
    ...state,
    courses: { ...state.courses, [student.uuid]: courses },
  })),
  on(goalsActions.updateSelectedFacets, (state, { selectedFacets }) => ({
    ...state,
    page: 1,
    classes: [],
    selectedFacets,
  })),
  on(goalsActions.updateFacets, (state, { facets }) => ({
    ...state,
    facets,
  })),
  on(goalsActions.continueLoad, (state) => ({
    ...state,
    page: state.page + 1,
  })),
  on(goalsActions.updateLevel, (state, { level }) => ({
    ...state,
    level,
    hits: null,
    facets: {},
    selectedFacets: {},
    page: 1,
  })),
  on(goalsActions.loadGoalsSuccess, (state, { goals }) => ({
    ...state,
    goals: { ...state.goals, ...goals },
  })),
  on(goalsActions.loadTargetsSuccess, (state, { targets }) => ({
    ...state,
    targets,
  })),
  on(goalsActions.loadLocationsForProductSuccess, (state, { entity, locations }) => ({
    ...state,
    locations: {
      ...state.locations,
      [entity]: {
        ...state.locations[entity],
        ...locations,
      },
    },
  })),
  on(goalsActions.loadStudentLocationSuccess, (state, { entity, locations }) => ({
    ...state,
    locations: {
      ...state.locations,
      [entity]: locations,
    },
  })),
  on(goalsActions.loadBenchmarksSuccess, (state, { benchmarks }) => ({
    ...state,
    benchmarks: { ...state.benchmarks, ...benchmarks },
  })),
  on(goalsActions.benchmarksCanceled, (state, { entity }) => ({
    ...state,
    benchmarks: omit(state.benchmarks, entity),
  })),
  on(goalsActions.cancelGradeGoal, (state, { goal }) => ({
    ...state,
    goals: omit(state.goals, `${goal.productKey}_${goal.gradeKey}`),
  })),
  on(goalsActions.saveGradeGoalSuccess, (state, { goal }) => ({
    ...state,
    goals: {
      ...omit(state.goals, `${goal.productKey}_${goal.gradeKey}`),
      ...{ [`${goal.productKey}_${goal.gradeKey}`]: [goal] },
    },
  })),
  on(goalsActions.loadLockSuccess, (state, { lock }) => ({
    ...state,
    locks: { ...state.locks, ...lock },
  })),
  on(
    goalsActions.updateLock,
    goalsActions.updateLockFailed,
    (state, { organization, entity, locked }) => ({
      ...state,
      locks: { ...state.locks, [`${organization}_${entity}`]: locked },
    }),
  ),
  on(goalsActions.cancelLock, (state, { lockId }) => ({
    ...state,
    locks: omit(state.locks, lockId),
  })),
  on(goalsActions.studentCoursesChangedSuccess, (state, { student, courses }) => {
    const newHits = cloneDeep(state.hits) as StudentAnalytics[];
    updateAssignments(newHits, student, courses);
    return {
      ...state,
      hits: newHits,
    };
  }),
  on(goalsActions.studentCoursesChangedFailed, (state, { student, courses }) => {
    const newHits = cloneDeep(state.hits) as StudentAnalytics[];
    revertUpdateAssignments(newHits, student, courses);
    return {
      ...state,
      hits: newHits,
    };
  }),
);

function updateAssignments(hits: StudentAnalytics[], student: StudentAnalytics, courses: Course[]) {
  const studentHit = hits.find((hit) => hit.uuid === student.uuid);
  if (isNil(studentHit)) {
    return;
  }
  courses.forEach((course) => {
    if (isNil(studentHit.courseAssignments)) {
      studentHit.courseAssignments = createCourseAssignment();
    }
    studentHit.courseAssignments[course.product] = course.assigned;
  });
}

function revertUpdateAssignments(
  hits: StudentAnalytics[],
  student: StudentAnalytics,
  courses: Course[],
) {
  const studentHit = hits.find((hit) => hit.uuid === student.uuid);
  if (isNil(studentHit)) {
    return;
  }
  courses.forEach((course) => {
    if (isNil(studentHit.courseAssignments)) {
      studentHit.courseAssignments = createCourseAssignment();
    }
    studentHit.courseAssignments[course.product] = !course.assigned;
  });
}

function createCourseAssignment() {
  return {
    curriculet: false,
    ems: false,
    erp: false,
    smartstart: false,
    upstart: false,
    wacs: false,
  };
}

export function reducer(state, action) {
  return rosteringReducer(state, action);
}
